@use "@angular/material" as mat;

@mixin form-fieldset-typography($config){
    [legend]{
        font: {
          size: mat.font-size($config, subtitle-1);
          weight: mat.font-weight($config, subtitle-1);
        }
        opacity: .8;
    }
}
