@use '@angular/material' as mat;
@import "@angular/material/theming";
@import "app/shared/form-fieldset/fieldset.theme";
@import "app/user/edit-self/edit-self.theme";
@import "app/report/report.theme";
@import "app/report/basket/basket.theme";
@import "app/report/report-single/report-single.theme";
@import "app/report/report-book/report-book.theme";
@import "app/report/report-publisher-book/report-publisher-book.theme";
@import "app/report/report-single/report-single-table/report-single-table.theme";
@import "app/report/report-exhibition/report-exhibition.theme";
@import "app/report/report-architectural-art/report-architectural-art.theme";

$typography: mat.define-typography-config(
  $font-family: "Calibri, sans-serif",
  $headline-1: mat.define-typography-level(1.5rem, 1.5rem * 1.5, 700),
  $headline-2: mat.define-typography-level(1.25rem, 1.25rem * 1.5, 700),
  $subtitle-1: mat.define-typography-level(1rem, 1rem * 1.5, 700),
  $subtitle-2: mat.define-typography-level(1.125rem, 1.125rem * 1.5, 700),
  $body-1: mat.define-typography-level(0.875rem, 0.875rem * 1.5, 500),
  $body-2: mat.define-typography-level(0.875rem, 0.875rem * 1.5, 700),
  $button: mat.define-typography-level(0.875rem, 0.875rem * 1.5, 700)
);
/*override theme font because it breaks the alignment of material icons in mat-icon-buttons*/
.mat-mdc-icon-button {
  font-family: sans-serif !important;
  font-size: 12px !important;
}

@include mat.core();
@include mat.legacy-core();


$vgb-red: (
  main: #e3000f,
  lighter: #e3000f,
  darker: #e3000f,
  100: #e3000f,
  200: #e3000f,
  500: #e3000f,
  700: #e3000f,
  contrast: (
    main: #fff,
    lighter: #fff,
    darker: #fff
  )
);

$vgb-blue: (
  main: #626A6E,
  lighter: #626A6E,
  darker: #626A6E,
  100: #626A6E,
  200: #626A6E,
  500: #626A6E,
  700: #626A6E,
  contrast: (
    main: #fff,
    lighter: #fff,
    darker: #fff
  )
);

$primary: mat.define-palette($vgb-red);
$accent: mat.define-palette($vgb-blue);
$warn: mat.define-palette(mat.$red-palette);
$theme: mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn
  ),
  typography: $typography,
  density: 0
));

@include mat.all-component-themes($theme);
@include mat.all-legacy-component-themes($theme);
@include form-fieldset-typography($typography);
@include edit-self-typography($typography);
@include edit-self-theme($theme);
@include report-theme($theme);
@include report-typography($typography);
@include report-basket-theme($theme);
@include report-basket-typography($typography);
@include report-single-theme($theme);
@include report-single-typography($typography);
@include report-book-theme($theme);
@include report-publisher-book-theme($theme);
@include report-book-typography($typography);
@include report-single-table-theme($theme);
@include report-exhibition-theme($theme);
@include report-architectural-art-theme($theme);
@include report-exhibition-typography($typography);


mat-icon.trash-icon {
  transform: scale(0.9);
}

img.logo {
  max-width: 10rem;
  margin: 2rem auto;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}
