@use 'sass:map';
@use "@angular/material" as mat;
@import "./theme.scss";
@import "./app/core/material-usage/material-usage.overrides";

$background: map.get($theme, background);
$primary: map.get($theme, primary);

mat-form-field {
  width: 100%;
}

html {
  background: mat.get-color-from-palette($background, background);
}

body {
  height: 100%;
  margin: 0;

  font-family: Calibri, "Helvetica Neue", sans-serif;
}

* {
  box-sizing: border-box;
}

h1 {
  @include mat.typography-level($typography, headline-1);
  color: mat.get-color-from-palette($accent, default);
}

h2 {
  @include mat.typography-level($typography, headline-2);
  color: mat.get-color-from-palette($accent, default);
}

h3 {
  @include mat.typography-level($typography, subtitle-2);
  color: mat.get-color-from-palette($accent, default);
}

h4 {
  @include mat.typography-level($typography, subtitle-1);
  color: mat.get-color-from-palette($accent, default);
}


button[color="primary"] {
  color: white;
}

@media print {
  .page-break {
    page-break-after: always;
    list-style-type: none;
  }
}

@for $i from 2 through 4 {
  .grid-#{$i}-columns {
    display: grid;
    grid-template-columns: repeat($i, 1fr);
    column-gap: 1rem;
  }
}

.deletable-field {
  display: grid;
  grid-template-columns: auto 20%;

  button {
    place-self: center;
  }
}

.flex {
  display: flex;
}

.flex-1-1-auto {
  flex: 1 1 auto;
}

.m-b-rem-1 {
  margin-bottom: 1rem;
}

.no-wrap {
  white-space: nowrap;
}

@font-face {
  font-family: 'Circular';
  src: url("assets/fonts/CircularXXWeb-Regular.woff2");
  font-weight: normal;
}

@font-face {
  font-family: 'Circular';
  src: url("assets/fonts/CircularXXWeb-Bold.woff2");
  font-weight: bold;
}

@font-face {
  font-family: 'Circular-Medium';
  src: url("assets/fonts/CircularXXWeb-Medium.woff2");
  font-weight: normal;
}

