@use 'sass:map';
@use "@angular/material" as mat;

@mixin report-publisher-book-theme($theme) {
  $primary: map.get($theme, primary);
  $background: map.get($theme, background);

    vgb-publisher-book-form {
      background: mat.get-color-from-palette($background, card);

        small {
          color: mat.get-color-from-palette($primary);
        }
    }
}

@mixin report-book-typography($config) {
    vgb-publisher-book-form {
        [heading] {
          @include mat.typography-level($config, headline-2);
        }
    }
}
