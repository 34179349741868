@use "@angular/material" as mat;
@use 'sass:map';

@mixin report-exhibition-theme($theme){
  $background: map.get($theme, background);

    vgb-exhibition-form{
      background: mat.get-color-from-palette($background, card);

        [vgbExhibitionFormHeader] > small{
          color: mat.get-color-from-palette($primary);
        }
    }
}

@mixin report-exhibition-typography($config){
    vgb-exhibition-form{
        [vgbExhibitionFormHeader]{
        }
    }
}
