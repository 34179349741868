@use 'sass:map';
@use "@angular/material" as mat;

@mixin report-architectural-art-theme($theme) {
  $background: map.get($theme, background);

  vgb-architectural-art-form {
    background: mat.get-color-from-palette($background, card);

    .form-header > small {
      color: mat.get-color-from-palette($primary);
    }
  }
}
