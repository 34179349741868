@use '@angular/material' as mat;
@use 'sass:map';

@mixin edit-self-theme($theme) {
  $background: map.get($theme, background);

  vgb-user-edit-self {
    .user-edit-form {
      @include mat.elevation(2);
      background: mat.get-color-from-palette($background, card);
    }

    .user-edit-disabled {
      background: mat.get-color-from-palette($background, disabled-button);
    }
  }
}

@mixin edit-self-typography($config) {
  vgb-user-edit-self {

  }
}
