@use '@angular/material' as mat;
@use 'sass:map';

@mixin report-book-theme($theme){
  $background-palette: map.get($theme, 'background');
  $primary-palette: map.get($theme, 'primary');

  vgb-book-form {
    background: mat.get-color-from-palette($background-palette, card);


    small {
      color: mat.get-color-from-palette($primary-palette);
    }
  }
}


@mixin report-book-typography($theme) {
  $typography-config: mat.get-typography-config($theme);

  vgb-book-form {
    [heading] {
      @include mat.typography-level($typography-config, headline-2);
    }
  }
}
