@use "@angular/material" as mat;
@use 'sass:map';

@mixin report-single-table-theme($theme){
  $warn: map.get($theme, warn);
  $errorIndicator-background: mat.get-color-from-palette($warn);
  $errorIndicator-color: mat.get-color-from-palette($warn, 'default-contrast');

    vgb-report-single-table{
        .report-single-sum-indicator{
            b.indicateError{
                background: $errorIndicator-background;
                color: $errorIndicator-color;
            }
        }
    }
}
