@use "@angular/material" as mat;
@use 'sass:map';

@mixin report-basket-theme($theme){
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $primary: map.get($theme, primary);

    vgb-report-basket{
        header{
          background: mat.get-color-from-palette($mat-grey, 800);

            h1{
                color: #fff;
            }
            h2{
                color: rgba(255, 255, 255, .8);
            }
        }
    }
}

@mixin report-basket-typography($config){
    vgb-report-basket{
        header{
        }
    }
}
