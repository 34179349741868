.mat-button,
.mat-raised-button,
.mat-stroked-button,
.mat-fab{
    text-transform: uppercase;
}

.mat-button,
.mat-raised-button,
.mat-stroked-button{
    mat-icon{
        margin: 0 5px 3px 0;
    }
}

.mat-card{

    mat-card-content{
        [mat-table]{
            width: 100%;

            background: transparent;
        }
    }

    .mat-card-actions{
        display: flex;

        button{
            flex: 0 0 auto;

            text-transform: uppercase;
        }
        button[mat-icon-button]:not(:last-child){
            margin-right: .5rem;
        }
        button:last-of-type{
            margin-right: 0;
        }

        .spacer{
            flex: 1;
        }
    }
}

.mat-form-field{
    .mat-form-field-prefix{
        .mat-icon{
            margin-right: .5rem;
        }
        .mat-datepicker-toggle{
            display: block;

            margin-left: -8px;
        }
    }

    &.autosize{
        .mat-form-field-infix{
            width: auto;
        }
    }
}

[mat-table]{ //be more specific than the styles of angular-material
    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type,
    th.mat-header-cell:first-of-type{
        padding-left: 16px; // material specs
    }
    td.mat-cell:last-of-type,
    td.mat-footer-cell:last-of-type,
    th.mat-header-cell:last-of-type{
        padding-right: 8px; // material specs
    }
}

.mat-select-panel.mat-select-multiline{
    .mat-option .mat-option-text{
        small{
            opacity: .8;
        }
    }
}

.mat-menu-content{

    .mat-divider:not(:first-child){
        margin: .5rem 0;
    }
    .mat-divider:not(:last-child){
        margin: .5rem 0;
    }

}
