@use '@angular/material' as mat;
@use 'sass:map';

@mixin report-theme($theme){
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $primary: map.get($theme, primary);

    vgb-report{
    }

    vgb-report-basket{
      background: mat.get-color-from-palette($background, card);
    }
}

@mixin report-typography($config){
    vgb-report{
    }
}

.creator-number-card {
    margin-bottom: 1rem;
    width: 20rem;

    h4 {
        margin: 0;
    }
}
